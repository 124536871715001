import React, { Fragment, useState, useEffect } from 'react';
import { TextGlower } from '../assets/TextGlower';
import Cookies from 'js-cookie';
import { useCreateRequest } from '../hooks/requests'
import { useFetchSongs } from '../hooks/songs'
import { Autocomplete, TextField } from '@mui/material';
import { styled, lighten, darken } from '@mui/system';

const defaultInput = {
  song: null,
  amount: null
};

const Page = () => {
  const [hasVoted, setHasVoted] = useState(false);
  const [error, setError] = useState(false);
  const [songList, setSongList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ input, setInput ] = useState(defaultInput);
  const addRequest = useCreateRequest();
  const getSongs = useFetchSongs();

  const updateInput = (update) => {
    setInput({...input, ...update})
  };

  useEffect(async () => {
    
    const songs = Cookies.get('SongList');
    
    if(!songs){
      getSongs.mutate(null, {
        onSuccess: (response) => {
          var in4hours = 1.0/6;
          Cookies.set('SongList', JSON.stringify(response.data), { expires: in4hours });
          setSongList(response.data)
        },
      })  
    }
    else{
      setSongList(JSON.parse(songs))
    }   
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    addRequest.mutate(input, {
      onSuccess: (response) => {
        setHasVoted(true);
        setIsSubmitting(false);
      },
    });
  };

  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: "#fff",
    backgroundColor:
      'dark' === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken("#000", 0.8),
  }));
  
  const GroupItems = styled('ul')({
    padding: 0,
  });
  
    const options = songList.map((option) => {
      const firstLetter = option.song[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
      };
    });

  return (
    <section className="rounded-lg px-4 py-6 sm:p-8 max-w-3xl mx-auto">
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/js/bootstrap.bundle.min.js" integrity="sha384-gtEjrD/SeCtmISkJkNUaaKMoLD0//ElJ19smozuHV6z3Iehds+3Ulb9Bn9Plx0x4" crossorigin="anonymous"></script>
      <div className="grid gap-4">
        {hasVoted ?
        <></>
        :
        <center>
        {TextGlower("REQUEST YOUR SONG", 1.7)} 
          <br/>               
      </center>
        }
     
        <div className="grid gap-4 sm:gap-6 mb-2 sm:mb-4">
          {isSubmitting ? <><center>Processing...</center></> : !hasVoted ? (
    
          <form onSubmit={(e) => handleSubmit(e)}>       
       
       <div class="form-floating">

       {TextGlower("SONG:", 0.75)}

       <TextField 
                sx={{ 
                  width: '100%', 
                  backgroundColor: '#fff' ,
                  fontSize: '1rem',
                  lineHeight: 2,
                  color: '#212529',
                  borderRadius: '0.5rem',
                  marginBottom: '1rem',
                  boxShadow: '0 0 1rem #6342AA'
                }} 
                type='text'
                class="form"
                value={input.song} 
                onChange={(event) => updateInput({song: event.target.value})} 
                required>
              </TextField>
             </div>
             

             <center>
             <p style={{fontSize: '0.8rem', marginBottom: 5}}>
             {TextGlower("Tip the DJ to increase the chance of getting your song!", 0.7)}
              <span style={{fontSize: '0.7rem'}}> </span>
              </p>  
              </center>
              
             <div class="form-floating">
             {TextGlower("TIP:", 0.75)}
             <TextField 
                sx={{ 
                  width: '100%', 
                  backgroundColor: '#fff' ,
                  fontSize: '1rem',
                  lineHeight: 2,
                  color: '#212529',
                  borderRadius: '0.5rem',
                  marginBottom: '1rem',
                  boxShadow: '0 0 1rem #6342AA'
                }} 
                type='number'
                class="form"
                value={input.amount} 
                onChange={(event) => updateInput({amount: event.target.value})} 
                required>
              </TextField>
             </div>
                <div style={{display:'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                <div class='tip-btn' style={input.amount < 25 && input.amount >= 10 ? {backgroundColor: "lightgreen"}: {}} onClick={() => updateInput({amount : 10})}>{TextGlower("10 kr")}</div>
                <div class='tip-btn' style={input.amount < 50 && input.amount >= 25 ? {backgroundColor: "lightgreen"}: {}} onClick={() => updateInput({amount : 25})}>{TextGlower("25 kr")}</div>
                <div class='tip-btn' style={input.amount < 100 && input.amount >= 50 ? {backgroundColor: "lightgreen"}: {}} onClick={() => updateInput({amount : 50})}>{TextGlower("50 kr")}</div>
                <div class='tip-btn' style={input.amount >= 100 ? {backgroundColor: "lightgreen", boxShadow: '0 0 2rem #FFD700	',}: {}} onClick={() => updateInput({amount : 100})}>{TextGlower("100 kr")}</div>
              </div>
              <p style={{color:'red', fontSize: '0.6rem', marginBottom: '0.4rem'}}>
                <center>! YOU WILL ONLY GET CHARGED IF YOUR SONG GETS ACCEPTED !</center>
              </p>  
             <center>
              <button class="btn-lg tip-btn" style={{lineHeight:1.5, padding: '0.2rem 2.5rem'}} type="submit">{TextGlower("Submit",1.25)}</button>
              </center>
           </form>
          ) : (
            <Fragment>
              <br/>
              <center> <h2>{TextGlower("Thank you for requesting!", 1.5)}</h2> <br/>
              {TextGlower("The DJ will consider your request and hopefully play it within the next 30 minutes, if he thinks it is suitible.", 0.9)}
              <p style={{fontSize : '0.75rem', color: 'red'}}>
                No real payment has or will be made, it's just an experiment to see if you guests are willing to pay for a song requests or not 😘
              </p>
              {TextGlower("If you want to request another song you can just refresh the page and send a new request!", 0.65)}
                          

              </center>
            </Fragment>
          )}
        </div>
        {error ? <p className="m-0 text-sm font-bold text-red-500">{error.message}</p> : null}
      </div>
    </section>
  );
};

export default Page;
