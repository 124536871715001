import { useMutation } from "react-query";

export const useFetchSongs = () => {
    return useMutation(["songlist"], async () => {
        const response = await fetch('/api/get-songs', {
            method: 'GET'
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        const result = await response.json();
        return result;
    });
};
