import { useMutation } from "react-query";

export const useCreateRequest = () => {
    return useMutation(["requests"], async (data) => {
        const response = await fetch('/api/post-vote', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        const result = await response.json();
        return result;
    });
};
